<template>
  <div class="page">
    <!--反馈类型-->
    <div class="header">
      <div class="title">反馈类型</div>
      <div class="h-types">
        <div :class="typeItem===item.feedT ? 'h-types-selected':' h-types-item'" v-for="item in feedList" :key='item.id' @click='feedType(item.feedT)'>
          <span class="h-types-text">{{item.feedName}}</span>
        </div>   
      </div>
    </div>
    <!-- 反馈内容 -->
    <div class="content">
      <div class="title">填写内容</div>
      <div class="c-area">
          <van-field
                    v-model="desQuest"
                    rows="6"
                    autosize
                    type="textarea"
                    maxlength="300"
                    placeholder="请详细描述您的问题或建议，我们将及时跟进"
                    show-word-limit
                    @change='changeQuest($event)'
                    />
      </div>
      <!-- 添加照片 -->
      <div class="c-upload">
       
        <van-uploader v-model="fileList"  :max-count="3" upload-text="添加图片"  :preview-full-image="false" :max-size="1000*1024"  @oversize="onOversize" :before-read="beforeRead" :after-read="afterRead"/>
      </div>
      <div class="c-desc">最多可上传3张图片，图片大小上限1M</div>
    </div>
    <!--描述文案-->
    <div class="c-tips">收到问题后，工作人员将在1小时内联系您，请注意接听电话</div>

    <!--  页脚  -->
    <div class="footer">
      <div class="f-btn" @click="submit">
        <span class="f-btn-text">提交</span>
      </div>
    </div>
  </div>

</template>

<script>
import API from '../../server/api.js'
import { onMounted, reactive, toRefs } from 'vue'
import APP from '../../utils/APP'
import {Toast} from 'vant';
export default {
  name: 'index',
  setup() {
    const state = reactive({
       feedList:[
        {feedT:"AUTH",feedName:"资料认证"},
        {feedT:"BORROW",feedName:"借款"},
        {feedT:"REPAY",feedName:"还款"},
        {feedT:"BANK",feedName:"银行卡"},
        {feedT:"FEE",feedName:"费用"},
        {feedT:"OTHER",feedName: "其他"},
        {feedT:"ERROR",feedName:"系统故障"},
        {feedT:"ADVICE",feedName:"建议"},
       ],
        status:true,
        desQuest:'',
        typeItem:"AUTH",
        fileList:[],
        fileName:'',
        fileType:'',
        newList:[]
    })
    const feedType=(item)=>{
      // console.log(item,23);
      state.typeItem=item
      state.status=false
    }
    const changeQuest=(e)=>{
            // console.log(e.target.value);
            state.desQuest=e.target.value
    }
    //限制图片大小
    const onOversize=(file)=>{
       Toast('文件大小不能超过 1M'); 
    }
    const beforeRead=(file)=>{
      state.fileName=file.name
      state.fileType=file.type
      if (file.type!=='image/jpeg' && file.type!=='image/png' && file.type!=='image/jpg') {
        Toast('请上传正确格式图片');
        return false;
      }
      return true
      
    }
    const afterRead=async(file)=>{
       //上传图片到oss
      const result=await API.uploadImg({
          fileName:state.fileName,//图片名
          fileType:state.fileType
      })
      if(result){
        const imgUrl=result.host + result.dir;
        state.newList.push(imgUrl)  
      }
      //  console.log(state.newList);
    }

   
    //提交
    const submit=async()=>{
     
      if(state.desQuest == '') {
            Toast('请输入反馈内容');
            return false;
      }
       if(state.newList.length==0) {
            Toast('请上传图片');
            return false;
      }
      const result= await API.feedback({
        feedContent:state.desQuest,
        feedType:state.typeItem,
        imgList: state.newList
      })
     //提交成功要做什么
    //  console.log(result);
      // if(){
        APP.GO_BACK(1)
      // }

    }
    return{
      ...toRefs(state),
      submit,
      feedType,
      changeQuest,
      onOversize,
      beforeRead,
      afterRead
    }
    
  }
}   
</script>

<style lang="less" src="./index.less" scoped>

</style>
